body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #BBD2C5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #536976, #BBD2C5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #536976, #BBD2C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input{
  height: 30px;
  width: 110px;
  margin:10px;
  border-radius: 10px;
  border-style: none;
  padding: 5px;
  font-weight: 700;
}

.button{
  height: 40px;
  border-style:none ;
  border-radius: 10px;
  margin-top: 2%;
  cursor: pointer;

}

.button:hover{
  background-color: beige;
}


.section__meetings{
 margin-top: 5%;
width: 80%;
  border: 1px solid black;

}



.section__meetings ul li {
  display: flex;
  align-items: center;
  justify-content:space-evenly;


}

.section__meetings ul {
  padding: 0;
  list-style: none;
}



@media (max-width: 480px ) {


  .section__meetings ul li {

  flex-direction: column;

  }

 
}